:root {
  --color-fund: rgba(255, 255, 255, 0.6);
}

.home,
.services,
.reports,
.contact,
.events,
.support {
  display: flex;
  margin-left: 260px;
  font-size: 2rem;
}

.pe-auto{
  cursor: pointer;
}

* {
  transition: all 0.3s;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #0C392E;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.side p {
  text-align: center;
  margin: 0;
  padding: 0;
}

.side-card {
  border: solid 1px rgba(0, 0, 0, 0.175);
  border-radius: 3px;
  height: 32px;
  background: rgba(1, 84, 49, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes side-card-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.side-card-blink {
  -webkit-animation: side-card-blink 1s linear infinite;
  -moz-animation: side-card-blink 1s linear infinite;
  -ms-animation: side-card-blink 1s linear infinite;
  -o-animation: side-card-blink 1s linear infinite;
  animation: side-card-blink 1s linear infinite;

  border: solid 1px rgba(0, 0, 0, 0.175);
  border-radius: 5px;
  height: 33px;
  background: #FA6157;
}

.side-card br {
  margin: 0;
  padding: 0;
}

.card-h6-barchart {
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  margin-top: 0px;
  font-size: 18px;
  text-align: center;
  background: #539a73;
  padding: 5px;
  color: white;
}


.inputdata {
  border-radius: 5px;
  border: solid 1px grey;
  padding: 3px 5px 2px 5px;
  font-size: 13px;
}

.button {
  background: rgba(27, 93, 67, 0.8);
  padding: 2px 15px 1px 15px;
  border-radius: 5px;
  margin-left: 10px;
  color: #F7FAFC;
  border: solid 0px gray;
}

.button:hover {
  background: rgba(21, 67, 48, 0.8);
}

.contain {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.content {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.button-financial:hover {
  background: red;
}

.nav-bar {
  text-decoration: none;
  color: white;
  background-color: #0C392E;
  margin: 5px 5px;
  border-radius: 10px;
  padding: 5px 15px;
  border: solid 1px #0C392E;
}

.nav-bar:hover {
  color: white;
  border: solid 1px white;

}

#offcanvasNavbar-expand-false {
  background-color: #015431;
}

.navbar-toggler {
  border: solid 1px silver;
}

.name-company {
  color: white;
  margin: 0px 10px;
}

.name-company:hover {
  color: rgba(255, 255, 255, 0.77);
}

.nav-bar-top {
  display: inline-block;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.909);
  padding: 5px 15px;
  width: 100%;
}

.nav-bar-top:hover {
  color: #0C392E;
  background: #539a73;
}

#basic-nav-dropdown {
  width: 250px !important;
}

.nav-link-top {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.909);
  padding: 5px 10px;
}

.nav-link-top:hover {
  color: black;
}

.ico-bin {
  cursor: pointer;
  pointer-events: auto !important;
}

.ico-bin:hover {
  pointer-events: auto !important;
  color: black !important;
  transform: scale(1.1)
}
